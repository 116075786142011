import { LogoutInvalidToken, RefreshTokenReload, getCookie } from '../utils/utils';
import configData from './configData.json';

// const configObj = globalConfig.get('configsGlobal');

const HTTP_METHODS = Object.freeze({
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
});

const MicroserviceFetchUtil = (() => {
    const dataCache = {};

    const defaultConfig = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const clientConfigs = {
        "client_id": configData.mulesoftConfig.client_id,
        "client_secret":configData.mulesoftConfig.client_secret,
     "countryCode": configData.common.countryCode
    };
    let controller = new AbortController();

    const stringToHex = (str) => {
        var arr = [];
        for (var i = 0; i < str.length; i++) {
            arr[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
        }
        return arr.join('');
        
    };

    const mainRequest = (url, method, customerToken) => {
        console.log("customerToken", customerToken);
        var myHeaders = new Headers();
        myHeaders.append("client_id", configData.mulesoftConfig.client_id);
        myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
        myHeaders.append("customerToken", customerToken.customerToken);
        myHeaders.append("countryCode", customerToken.countryCode);
        
        var requestOptions = {
          method,
          headers: myHeaders,
          redirect: 'follow'
        };

        const finalURL = `${customerToken.countryCode ==='id' 
            ? configData.mulesoftConfig.microserviceEndpointId 
            : customerToken.countryCode === "pk"
            ? configData.mulesoftConfig.microserviceEndpointPk 
            : configData.mulesoftConfig.microserviceEndpoint}${url}`;
        const req = new Request(finalURL, requestOptions);
        console.log("req", req);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            return response.json();
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data.errors && data.errors.errorPayload && data.errors.errorPayload.error && data.errors.errorPayload.error.message ==="INVALID_ID_TOKEN" ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(error);
                });
        });
    }

    const postRequest = (url, method, customerToken, formData, flag = '') => {
        var myHeaders = new Headers();
        myHeaders.append("client_id", configData.mulesoftConfig.client_id);
        myHeaders.append("client_secret", configData.mulesoftConfig.client_secret);
        myHeaders.append("customerToken", customerToken.customerToken);
        myHeaders.append("countryCode", customerToken.countryCode);
        
        var requestOptions = {
          method,
          headers: myHeaders,
          body: formData,
          redirect: 'follow'
        };
        const MSEndpoint = customerToken.countryCode ==='id' 
        ? configData.mulesoftConfig.microserviceEndpointId 
        : customerToken.countryCode === "pk"
        ? configData.mulesoftConfig.microserviceEndpointPk 
        : configData.mulesoftConfig.microserviceEndpoint;
        const baseURL = flag === 'expAPI' ? configData.mulesoftConfig.muleExpEndpoint : MSEndpoint;
        const finalURL = `${baseURL}${url}`;
        const req = new Request(finalURL, requestOptions);
        console.log("req", req);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            return response.json();
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data.errors && data.errors.errorPayload && data.errors.errorPayload.error && data.errors.errorPayload.error.message ==="INVALID_ID_TOKEN" ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(error);
                });
        });
    }

    const request = (url, method, headerConfig, body) => {
        const headers = {
          ...defaultConfig,
          ...clientConfigs,
        //   "action":actionType,
          ...headerConfig
        };
        const requestConfig = {
            method,
            headers,
            body,
            signal: controller.signal,
        };
        console.log(headers,requestConfig)
        if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
            requestConfig.body =JSON.stringify(body)
        }
        const finalURL = `${headerConfig.countryCode ==='id' 
            ? configData.mulesoftConfig.microserviceEndpointId 
            : headerConfig.countryCode === "pk"
            ? configData.mulesoftConfig.microserviceEndpointPk
            : configData.mulesoftConfig.microserviceEndpoint}${url} `
        const req = new Request(finalURL, requestConfig);
        return new Promise((resolve, reject) => {
            fetch(req)
                .then(response => {
                    if (response) {
                        if (response.ok && response.status >= 200 && response.status <= 299) {
                            return response.json();
                        }
                        else if(response.status === 404){
                            return response.json();
                        } 
                        else if(response.status >= 500){
                            return response.json();
                        } 
                        else {
                            console.log(response)
                            throw new Error(response);
                        }
                    }
                })
                .then((data) => {
                    if(data.statusCode >= 500 && data.errors &&data.errors.errorPayload && data.errors.errorPayload.error && data.errors.errorPayload.error.message ==="INVALID_ID_TOKEN" ){
                        const refreshToken=getCookie("refreshToken");
                        if(refreshToken){
                            RefreshTokenReload(refreshToken);
                        }else{
                            LogoutInvalidToken();
                        }
                      }
                    dataCache[stringToHex(req)] = data;
                    resolve(data);
                }, (error) => {
                    reject(error);
                });
        });
    };

    const abort = () => controller.abort();

    return {
        post: (url, customerToken, formData, flag) => {
            return postRequest(url, HTTP_METHODS.POST, customerToken, formData, flag);
        },
        get: (url, customerToken) => {
            return mainRequest(url, HTTP_METHODS.GET, customerToken);
        },
        put: (url, headerConfig = {}, body) => {
            return request(url, HTTP_METHODS.PUT, headerConfig, body);
        },
        delete: (url, headerConfig = {}) => {
            return request(url, HTTP_METHODS.DELETE, headerConfig);
        },
        abort: () => {
            abort();
            controller = new AbortController();
        }
    }
})();

export default MicroserviceFetchUtil;