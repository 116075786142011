import React, { useState, useContext } from "react";
import styles from "./Header.module.scss";
import unileverLogo from "../../assets/images/unileverLogo.svg";
import Logout from "../../assets/images/logout.svg";
import UcubeAI from "../../assets/images/Frame.svg";
import hamMenu from "../../assets/images/hamMenu.svg";
import crossMenu from "../../assets/images/crossMenu.svg";
import opDashboard from "../../assets/images/opDashboard_blue.svg";
import ucubeGraph from "../../assets/images/ucubeGraph_blue.svg";
import logoutBlue from "../../assets/images/logout_blue.svg";
import userManagement from "../../assets/images/userManage_blue.svg";
import ucubeTextLogo from "../../assets/images/UCubeTextLogo.svg";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import Indonesia from "../../assets/images/Indonesia.svg";
import Vietnam from "../../assets/images/vietnam.svg";
import Thailand from "../../assets/images/Thailand.svg";
import Phillipines from "../../assets/images/Phillipines.svg";
import ProfileIcon from "../../assets/images/profileIcon.svg";
import dropupdown from "../../assets/images/dropupdown-white.svg";
import Pakistan from "../../assets/images/Pakistan.svg";
import { StoreContext } from "../ContextStore/StoreContextProvider";
import { useEffect } from "react";
import { getCookie, deleteCookie } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import MuleFetchUtil from "../../common/mule-fetch-util";
import { Tooltip } from "react-tooltip";
import Dropdown from "react-dropdown";
import "./dropDown.css";
import { doSSOLogout } from "../../firebase/ssoAuthenticate";

const Header = ({ ucube }) => {
  const history = useHistory();
  const selectedApp = localStorage.getItem("selectedApp");
  console.log("selectedApp", selectedApp);
  const [menu, setMenu] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const {
    userDetails,
    updateSelectedBsa,
    selectedBsa,
    updateCountryCode,
    selectedCountryCode,
  } = useContext(StoreContext);

  const [currentbsa, setCurrentbsa] = useState({});
  const handleMenu = () => {
    setMenu(!menu);
  };
  const handleBsaChange = (data) => {
    setCurrentbsa(data.label);
    updateSelectedBsa(data.label);
  };

  useEffect(() => {
    setCurrentbsa(userDetails && userDetails.bsa);
    updateCountryCode(localStorage.getItem("countryCode"));
  }, [selectedBsa, updateCountryCode, userDetails]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === "/operationalreportslandingpage"
    ) {
      localStorage.setItem("selectedApp", "operational");
    } else if (
      history &&
      history.location &&
      history.location.pathname === "/homepage"
    ) {
      localStorage.setItem("selectedApp", "ucube");
    } else if (
      history &&
      history.location &&
      history.location.pathname === "/useraccess"
    ) {
      localStorage.setItem("selectedApp", "useraccess");
    }
  }, [history]);
  console.log(userDetails);
  console.log(currentbsa);

  const onUcubeClick = () => {
    localStorage.setItem("selectedApp", "ucube");
    const location = {
      pathname: "/homepage",
    };
    history.replace(location);
    history.push({ state: { ucube: true } });
  };
  const onBackButtonClick = () => {
    const location = {
      pathname: "/landingscreen",
    };
    history.replace(location);
  };
  const onUsermanagementClick = () => {
    localStorage.setItem("selectedApp", "useraccess");
    const location = {
      pathname: "/useraccess",
    };
    setMenu(false);
    history.replace(location);
  };

  const onOperationalDashboardsClick = () => {
    //localStorage.setItem("selectedApp","operational");
    const location = {
      pathname: "/landingscreen",
    };
    history.replace(location);
    history.push({ state: { ucube: false } });
  };

  const emailId = userDetails && userDetails.email;

  const logoutHandler = async () => {
    if (getCookie("isSSOLogin") || getCookie("isMFALogin")) {
      await doSSOLogout();
      getCookie("idToken") && deleteCookie("idToken");
      getCookie("refreshToken") && deleteCookie("refreshToken");
      getCookie("isSSOLogin") && deleteCookie("isSSOLogin");
      getCookie("isMFALogin") && deleteCookie("isMFALogin");
      localStorage.removeItem("countryCode");
      localStorage.removeItem("isIndonesia");
      localStorage.removeItem("isVietnam");
      localStorage.removeItem("isPhilipines");
      localStorage.removeItem("isThailand");
      localStorage.removeItem("isPakistan");
      localStorage.removeItem("adminAccess");
      history.push("/login");
    } else {
      let email = emailId && emailId.toLowerCase();
      let obj = {
        email: email,
      };
      if (getCookie("idToken") !== null) {
        MuleFetchUtil.post(
          "fb/accounts/logout",
          { countryCode: selectedCountryCode },
          obj,
          "logout"
        )
          .then((response) => {
            if (
              response.statusCode === 200 &&
              response.statusMessage === "SUCCESS"
            ) {
              deleteCookie("idToken");
              if (getCookie("refreshToken") !== null) {
                deleteCookie("refreshToken");
              }
              history.push("/login");
            } else {
              console.log("Logout unsuccessful:", response);
            }
          })
          .catch((error) => {
            console.log("Error logging out:", error);
          });
      }
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headertop}>
          <div className={styles.dttReports}>
            <img
              src={arrowLeft}
              onClick={onBackButtonClick}
              className={styles.backArrow}
              alt="backArrow"
              data-testid='back'
            />
              <img src={UcubeAI} alt="" />
          </div>
          <div className={menu ? styles.menuSide : styles.navlinks}>
            {userDetails &&
              userDetails.accessToUcube === "YES" &&
              userDetails.role !== null && (
                <div onClick={onUcubeClick}>
                  <img src={ucubeGraph} className={styles.menuIcons} alt="" />{" "}
                  <p
                    className={selectedApp === "ucube" ? styles.underLine : ""}
                  >
                    {" "}
                    UCube
                  </p>
                </div>
              )}
            {userDetails &&
              userDetails.accessToOperationalDashboards === "YES" && (
                <div onClick={onOperationalDashboardsClick}>
                  <img src={opDashboard} className={styles.menuIcons} alt="" />
                  <p
                    className={
                      selectedApp === "operational" ? styles.underLine : ""
                    }
                  >
                    {" "}
                    Reports & Analytics Home
                  </p>
                </div>
              )}
            {userDetails && userDetails.userType === "admin" && (
              <div onClick={onUsermanagementClick}>
                <img src={userManagement} className={styles.menuIcons} alt="" />
                <p
                  className={
                    selectedApp === "useraccess" ? styles.underLine : ""
                  }
                >
                  {" "}
                  User management
                </p>
              </div>
            )}
            <span onClick={logoutHandler}>
              <img src={Logout} className={styles.logout} alt="" />
              <img src={logoutBlue} className={styles.logout_blue} alt="" />
              <p>Logout</p>
            </span>
          </div>
          <div className={styles.country_popup}>
            <div className={styles.countryName}>
              <img
                src={
                  selectedCountryCode === "id"
                    ? Indonesia
                    : selectedCountryCode === "vn"
                    ? Vietnam
                    : selectedCountryCode === "ph"
                    ? Phillipines
                    :selectedCountryCode === "pk"
                    ? Pakistan
                    : Thailand
                }
                className={styles.countryLogo}
                alt=""
              />
              <p className={styles.name}>
                {selectedCountryCode === "id"
                  ? "Indonesia"
                  : selectedCountryCode === "vn"
                  ? "Vietnam"
                  : selectedCountryCode === "ph"
                  ? "Philippines"
                  :selectedCountryCode === "pk"
                  ? "Pakistan"
                  : "Thailand "}
              </p>
            </div>
            <div className={styles.profileIcon}>
              <img
                src={ProfileIcon}
                alt="Profileicon"
                data-tooltip-id="profilePopup"
              />
            </div>
            <Tooltip
              className={styles.popupModal}
              id="profilePopup"
              place="bottom"
              openOnClick="true"
              offset="8"
              noArrow
              clickable
            >
              <div className={styles.popup}>
                <div className={styles.popupDetails}>
                  <h4>{userDetails.name}</h4>
                  <p>{userDetails.email}</p>
                  <div className={styles.userDetailsPopup}>
                    <span className={styles.popup_role}>
                      Role<p>{userDetails.role}</p>
                    </span>
                    <span className={styles.popup_role}>
                      Business Scope Assignment
                      {/* {
                      Object.keys(userDetails).length > 0 &&
                      <select
                        className={styles.popupDetails_bsaDropdown}
                        value={selectedBsa}
                        onChange={handleBsaChange}
                        data-testid="select"
                      >
                        {userDetails && userDetails.bsa && userDetails.bsa.map((data) => (
                          <option data-testid="select-option" value={data}>{data}</option>
                        ))}
                      </select>
                    } */}
                      {userDetails && userDetails.bsa && (
                        <div className="popupDropDown-container">
                          <Dropdown
                            options={userDetails.bsa}
                            onChange={handleBsaChange}
                            value={
                              selectedBsa ? selectedBsa : userDetails.bsa[0]
                            }
                          />
                          {/* <img src={dropdownBlue} alt="dropdown"/> */}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div onClick={logoutHandler} className={styles.popupLogout}>
                  <img src={logoutBlue} alt="" />
                  <p>Logout</p>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={menu ? styles.menuOpenView : styles.mobileViewAdjust}>
        <div className={menu ? styles.noneHeader : styles.header1}>
          <p>Hello {userDetails.name}</p>
          <img
            className={dropDown ? styles.dropupdowntrue : styles.dropupdown}
            src={dropupdown}
            onClick={() => setDropDown(!dropDown)}
            alt="dropdown"
            data-testid='click'
          />
        </div>
        <div className={menu ? styles.headerSidebar : styles.headerbottom}>
          <div className={styles.hamMenu} onClick={handleMenu}>
            <img src={menu ? crossMenu : hamMenu} alt="menu" />
          </div>
          <div>
            <div className={dropDown ? styles.dropDown : styles.details}>
              <p className={styles.detailsRole}>
                Role <b>{userDetails.role}</b>
              </p>
              <p className={`${styles.assignment} bsaDropDownMobile`}>
                <span>Business Scope Assignment</span>
                {/* {
            Object.keys(userDetails).length>0 &&
              <select 
              className={styles.bsaDropdown}
              value={selectedBsa}
              onChange={handleBsaChange}
              data-testid="select"
              >
              {userDetails && userDetails.bsa && userDetails.bsa.map((data)=>(
                <option data-testid="select-option" value={data}>{data}</option>
                ))}
            </select>
              } */}
                {userDetails && userDetails.bsa && (
                  <Dropdown
                    options={userDetails.bsa}
                    onChange={handleBsaChange}
                    value={selectedBsa ? selectedBsa : userDetails.bsa[0]}
                    arrowClosed={<span className="arrow-closed" />}
                    arrowOpen={<span className="arrow-open" />}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
